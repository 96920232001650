import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const StatsBoxes = () => {
  const stats = [
    {
      title: "Turnover",
      icon: "/assets/icons/shopping cart.svg",
      value: "$92,405",
      percent: "5.39%",
    },
    {
      title: "Profit",
      icon: "/assets/icons/Currency dollar.svg",
      value: "$32,218",
      percent: "5.39%",
    },
    {
      title: "New customer",
      icon: "/assets/icons/Circle.svg",
      value: "298",
      percent: "6.84%",
    },
  ];

  return (
    <Container className="mt-5">
      <div className="d-flex align-items-center gap-2">
        <img
          src="/assets/icons/overview.svg"
          alt="overview"
          width={24}
          height={24}
        />
        <p className="overview_text fw-bold">Overview</p>
      </div>
      <Row className="mt-4">
        {stats?.map((item, index) => {
          return (
            <Col key={index} md="4" className="pe-4 mt-3 mt-md-0">
              <div className="statsbox p-3 rounded w-full">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold stats_title_text">{item?.title}</p>
                  <div className="stats_icon_box border rounded border-primary d-flex align-items-center justify-content-center me-2 ">
                    <img src={item?.icon} alt="" width={20} height={20} />
                  </div>
                </div>
                <h3>{item?.value}</h3>
                <p className="mt-2  stat_percent_text">
                  &#128897; {item?.percent} <span>period of change</span>
                </p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default StatsBoxes;

import React, { useState  } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import InputField from "../InputField/InputField";
import Carousel from "../components/Carousel.jsx";
import axios from "axios";

const Login = () => {

  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const requestData = {
      companyName: company,
      email: email,
      password: password
    };

    axios.post("https://api.shield4pay.com/auth/login", requestData)
      .then(response => {
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem("session", token);
          window.location.href = "/ClientInformation";
        } else {
          alert("Login failed");
        }
      })
      .catch(error => {
        console.error("Error occurred during login:", error);
      });
  };

  return (
    <div>
      <Container>
        <div className="space"></div>
        <Row className="d-flex ">
          <Col md="6">
            <h2 className="login_logo fw-bold">Shield4Pay</h2>
            <div className="space"></div>

            <h1 className="fw-semibold welcome_text mt-3">Welcome Back</h1>
            <p className="login_text fw-regular my-3">
              Login to access your company account
            </p>
            <InputField label="Email" value={email} onChange={setEmail}/>
            <InputField label="Company" value={company} onChange={setCompany} />
            <InputField label="Password" value={password} onChange={setPassword} />
            <button onClick={handleLogin} className="w-100 py-2 login_btn text-white rounded mt-4 ">
              Login
            </button>
          </Col>

          <Col md="6">
            <div className="slider_wrap px-4 d-none d-md-block">
              <Carousel />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;

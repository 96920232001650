import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Login from "./pages/Login";
import ClientInfo from "./pages/ClientInfo";
import DashboardAdmin from "./pages/DashboardAdmin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/DashboardAdmin" element={<DashboardAdmin />} />
        <Route path="/" element={<Login />} />
        <Route path="/ClientInformation" element={<ClientInfo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from "react";
import { Form } from "react-bootstrap";

const PrivacyInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="w-100">
      <Form.Label
        className="modal_label_text"
        style={{ fontFamily: "var(--inter)" }}
      >
        {props?.label}
      </Form.Label>
      <div className="clientinfo_input_wrap d-flex align-items-center px-2  w-100 border rounded-3">
        <input
          className="modal_input    w-100"
          {...props}
          ref={props?.inputRef}
          type={showPassword ? "text" : "password"}
        />
        {showPassword ? (
          <img
            src="/assets/icons/eye.svg"
            width="18"
            height="18"
            alt=""
            onClick={togglePasswordVisibility}
          />
        ) : (
          <img
            src="/assets/icons/Icon.svg"
            width="18"
            height="18"
            alt=""
            onClick={togglePasswordVisibility}
          />
        )}
      </div>
    </div>
  );
};

export default PrivacyInput;

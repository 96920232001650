import React, { useRef, useState, useEffect } from "react";
import TopBar from "../components/Navbar";
import { Col, Container, Row, Form } from "react-bootstrap";
import PrivacyInput from "../components/PrivacyInput";
import StandardInputField from "../components/StandardInputField";
import axios from "axios";

const ClientInfo = () => {
  const [clientInfo, setClientInfo] = useState(null);

  const inputWebhookRef = useRef(null);
  const inputUrlSuccessRef = useRef(null);
  const inputUrlErrorRef = useRef(null);

  const inputApiKeyRef = useRef(null);
  const inputSignKeyRef = useRef(null);

  useEffect(() => {
    fetchClientInfo();
  }, []);

  const fetchClientInfo = () => {
    const token = localStorage.getItem("session");
    if (token) {
      axios.get("https://api.shield4pay.com/account/", {
        headers: {
          "session": token
        }
      })
        .then(response => {
          setClientInfo(response.data);
        })
        .catch(error => {
          console.error("Error occurred while fetching client information:", error);
          window.location.href = "/";
        });
    }
  };

  const handleCopyClickApi = () => {
    // Select the input field
    inputApiKeyRef.current.select();
    // Copy the selected text
    document.execCommand("copy");
  };
  const handleCopyClickSign = () => {
    // Select the input field
    inputSignKeyRef.current.select();
    // Copy the selected text
    document.execCommand("copy");
  };
  const handleUpdateAccount = () => {
    const requestData = {
      webhook: inputWebhookRef.current.value,
      urlSuccess: inputUrlSuccessRef.current.value,
      urlError: inputUrlErrorRef.current.value
    };

    axios.post("https://api.shield4pay.com/account/update/", requestData, {
      headers: {
        "session": localStorage.getItem("session")
      }
    })
      .then(response => {
        if (response.status === 201) {
          alert("Account Update successful");
        } else {
          alert("Account Update failed");
        }
      })
      .catch(error => {
        console.error("Error occurred during account update:", error);
        alert("Account Update failed");
      });
  };

  const handleRenewKey = (apiKey) => {
    axios.post("https://api.shield4pay.com/account/renew/" + apiKey , null, {
      headers: {
        "session": localStorage.getItem("session")
      }
    }).then(response => {
        if (response.status === 201) {
          fetchClientInfo();
        }
    });
  };

  return (
    <div>
      <TopBar />
      {clientInfo ? (<Container className="mt-5">
        <div className="d-flex align-items-center gap-2">
          <img
            src="/assets/icons/overview.svg"
            alt="overview"
            width={24}
            height={24}
          />
          <p className="overview_text uppercase fw-bold">{clientInfo.companyName}</p>
        </div>
        <Row>
          <Row>
            <Col md="8">
              <div className="w-75">
                <StandardInputField
                  label="Tax"
                  type="text"
                  disabled
                  value={clientInfo.tax}
                />
              </div>
            </Col>
            <Col md="8">
              <div className="w-75">
                <StandardInputField
                  label="Webhook"
                  type="text"
                  defaultValue={clientInfo.webhook}
                  inputRef={inputWebhookRef}
                />
              </div>
            </Col>
            <Col md="8">
              <div className="w-75">
                <StandardInputField
                  label="UrlSuccess"
                  type="text"
                  defaultValue={clientInfo.urlSuccess}
                  inputRef={inputUrlSuccessRef}
                />
              </div>
            </Col>
            <Col md="8">
              <div className="w-75">
                <StandardInputField
                  label="UrlError"
                  type="text"
                  defaultValue={clientInfo.urlError}
                  inputRef={inputUrlErrorRef}
                />
              </div>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col md="8" className="d-flex align-items-center gap-2">
            <div className="w-75">
              <PrivacyInput
                inputRef={inputApiKeyRef}
                label="API Key"
                value={clientInfo.apiKey}
              />
            </div>
            <img
              src="/assets/icons/copy-Regular.svg"
              width="18"
              height="18"
              alt=""
              style={{ marginTop: "30px" }}
              onClick={handleCopyClickApi}
            />
            <img
              src="/assets/icons/clockwise.svg"
              width="18"
              height="18"
              alt=""
              style={{ marginTop: "30px" }}
              onClick={()=>handleRenewKey("apikey")}
            />
          </Col>
          <Col className=" d-flex align-items-end"></Col>
        </Row>
        <Row>
          <Col md="8" className="d-flex align-items-center gap-2">
            <div className="w-75">
              <PrivacyInput
                inputRef={inputSignKeyRef}
                label="Sign Key"
                value={clientInfo.signKey}
              />
            </div>
            <img
              src="/assets/icons/copy-Regular.svg"
              width="18"
              height="18"
              alt=""
              style={{ marginTop: "30px" }}
              onClick={handleCopyClickSign}
            />
            <img
              src="/assets/icons/clockwise.svg"
              width="18"
              height="18"
              alt=""
              style={{ marginTop: "30px" }}
              onClick={()=>handleRenewKey("signkey")}
            />
          </Col>
          <Col className=" d-flex align-items-end"></Col>
        </Row>
        <div className="d-flex flex-wrap align-items-center gap-2 mt-3">
          <button onClick={handleUpdateAccount} className="clientInfo_btn text-dark border rounded">
            Validate
          </button>
          <button onClick={()=>window.location.href = clientInfo.companyUrl} className="clientInfo_btn text-dark border rounded">
            Config Company
          </button>
          <button onClick={()=>window.location.href = clientInfo.dashboardUrl} className="clientInfo_btn text-dark border rounded">
            See Dashboard
          </button>
          {/*<button className="clientInfo_btn text-dark border rounded">
            Change password
          </button>*/}
        </div>
      </Container>) : ("")}
    </div>
  );
};

export default ClientInfo;

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.css";

function TopBar() {

  const handleDisctonnect = () => {
    localStorage.removeItem("session");
    window.location.href = "/";
  };

  return (
  
    <Navbar bg="light" className="bg-white border-bottom border-secondary-subtle" >
      <Container>
        <Navbar.Brand className="siteLogo" href="#home">Shield4Pay</Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Link onClick={handleDisctonnect}>
            <img src="/assets/icons/Logout.svg" width={24} height={24} />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default TopBar;

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./crousel.css";
import { Pagination } from "swiper/modules";

const Carousel = ({ slides }) => {
  return (
    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
      <SwiperSlide>
        <div className="slides"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slides"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slides"></div>
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;

import React, { useEffect, useState } from "react";
import TopBar from "../components/Navbar";
import StatsBoxes from "../components/StatsBoxes";
import { Col, Container, Row } from "react-bootstrap";
import Table from "../components/Table";
import CreateModal from "../components/CreateModal";
import EditModal from "../components/EditModal";

const DashboardAdmin = () => {
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  console.log(editModal);

  return (
    <div>
      <TopBar />

      <StatsBoxes />
      <Container>
        <div className="d-sm-flex align-items-center mt-5 gap-2 d-block">
          <div className="d-flex gap-2 align-items-center">
            <img
              src="/assets/icons/File.svg"
              alt="overview"
              width={24}
              height={24}
            />
            <p className="overview_text fw-bold">Companies </p>
          </div>
          <div className="d-flex align-items-center justify-content-end  gap-2 flex-sm-row flex-row-reverse ms-sm-auto mt-3 mt-sm-0 ">
            <div className="downloadbtn rounded-5   d-flex  align-items-center justify-content-center">
              <img
                src="/assets/icons/download.svg"
                width={24}
                height={25}
                alt=""
              />
            </div>
            <button
              onClick={() => setCreateModal(!createModal)}
              className="createbtn px-5 py-3 fs-6 rounded text-white"
            >
              <img src="/assets/icons/add.svg" alt="" /> Create company
            </button>
          </div>
        </div>
        <Table setEditModal={setEditModal} editModal={editModal} />
      </Container>
      <CreateModal show={createModal} onHide={() => setCreateModal(false)} setCreateModal={setCreateModal}  />
      <EditModal show={editModal} onHide={() => setEditModal(false)} setEditModal={setEditModal} />
    </div>
  );
};

export default DashboardAdmin;

import React from "react";
import { Form } from "react-bootstrap";

const StandardInputField = (props) => {
  return (
    <Form.Group className=" mb-2 mt-4" controlId="formBasicEmail">
      <Form.Label
        className="modal_label_text"
        style={{ fontFamily: "var(--inter)" }}
      >
        {props?.label}
      </Form.Label>
      <Form.Control
        {...props}
        ref={props?.inputRef}
        style={{ fontFamily: "var(--inter)" }}
        className="modal_input"
      />
    </Form.Group>
  );
};

export default StandardInputField;

import React, { useState } from "react";
import "./inputfield.css";

const InputField = ({ label, value: propValue, onChange }) => {
  // Use state to manage the input value
  const [inputValue, setInputValue] = useState(propValue || "");

  // Handle input change
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    // If onChange prop is provided, notify parent component
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div>
      <div className="label-float">
        <input
          type="text"
          placeholder=" "
          value={inputValue}
          onChange={handleInputChange}
          className="px-3"
        />
        <label>{label}</label>
      </div>
      <br />
    </div>
  );
};

export default InputField;
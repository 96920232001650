import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import EditModal from "../EditModal";
import { Navigate, useNavigate } from "react-router-dom";

const Table = (props) => {
  const { setEditModal, editModal } = props;
  const transactionsTableHeadings = [
    "Company name",
    "Password",
    "Webhook",
    "API Key",
    "Actions",
  ];
const navigate = useNavigate()
  const transactionsTableData = [
    {
      company_name: "Company A",
      password: "********",
      webhook: "https://example.com/webhook1",
      API_key: "abcdef123456",
      actions: ["Edit", "Delete"],
    },
    {
      company_name: "Company A",
      password: "********",
      webhook: "https://example.com/webhook1",
      API_key: "abcdef123456",
      actions: ["Edit", "Delete"],
    },
    {
      company_name: "Company A",
      password: "********",
      webhook: "https://example.com/webhook1",
      API_key: "abcdef123456",
      actions: ["Edit", "Delete"],
    },
  ];
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [tableData, setTableData] = useState(transactionsTableData);
  const [rows, setRows] = useState(5);
  const [selectedPage, setSelectedPage] = useState(1);

  let rowPerPage = 5;
  const onBackward = () => {
    setRows((prev) => (rows > rowPerPage ? prev - rowPerPage : rows));
  };
  const onForward = () => {
    setRows((prev) => (rows < tableData?.length ? prev + rowPerPage : prev));
  };
  useEffect(() => {
    transactionsTableData?.map((item) => {
      setCheckboxStates((prev) => ({
        ...prev,
        [item?.transactionId]: false,
      }));
    });
  }, []);
  const handleCheckboxChange = (column) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedCheckboxStates = {};

    // Update the state for each column checkbox
    Object.keys(checkboxStates).forEach((column) => {
      updatedCheckboxStates[column] = !selectAll;
    });

    setCheckboxStates(updatedCheckboxStates);
  };
  return (
    <div>
      <div
        className={`mt-5 rounded-2 border border-light-subtle table_wrapper`}
      >
        <table hover className={`mt-3 mb-0 table`}>
          <thead>
            <tr>
              <th className={`tableHeading checkbox}`}>
                <input
                  type="checkbox"
                  name="selectall"
                  id=""
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              {transactionsTableHeadings?.map((item, index) => (
                <th className="tableHeading" key={index}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item, index) => {
              if (index + 1 > rows - rowPerPage && index + 1 <= rows) {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        name={item?.app_name}
                        id={item?.app_name}
                        checked={checkboxStates[`${item?.app_name}`]}
                        onChange={() =>
                          handleCheckboxChange(`${item?.app_name}`)
                        }
                      />
                    </td>
                    <td style={{cursor:'pointer'}} data-title="Company name" onClick={() => navigate('/ClientInformation')}>{item?.company_name}</td>

                    <td data-title="Password">{item?.password}</td>
                    <td data-title="Webhook">{item?.webhook}</td>

                    <td data-title="API Key">{item?.API_key}</td>
                    <td data-title="Actions">
                      <img
                        onClick={()=> setEditModal(!editModal)}
                        src="/assets/icons/pen.svg"
                        width={20}
                        height={20}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        className="ms-2"
                        src="/assets/icons/Trash.svg"
                        width={20}
                        height={20}
                        alt=""
                      />
                      <img
                        className="ms-2"
                        src="/assets/icons/shield-lock.svg"
                        width={18}
                        height={18}
                        alt=""
                      />
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      <Row className="my-4"  >
        <Col md="6" className="d-flex align-items-center">
          <p className="">63 results</p>
        </Col>
        <Col md="6">
          <div className={`ms-auto pages_button_wrap`}>
            <button className="page_btn">
              <img
                src="/assets/icons/Chevronleft.svg"
                alt=""
                width={18}
                height={18}
              />
            </button>
            {[1, 1, 1, 1, 1, 1, 1].map((item, index) => {
              return (
                <button
                  onClick={() => setSelectedPage(index + 1)}
                  className={`page_btn `}
                  style={{
                    backgroundColor:
                      selectedPage === index + 1
                        ? "var(--blue-color)"
                        : "transparent",
                    color: selectedPage === index + 1 ? "#fff" : "#000",
                  }}
                  key={index}
                >
                  {index + 1}
                </button>
              );
            })}
            <button className="page_btn">
              <img
                src="/assets/icons/Chevronrightlarge.svg"
                alt=""
                width={18}
                height={18}
              />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Table;

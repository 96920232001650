import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CreateModal = (props) => {
  const {setCreateModal} = props

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-0w"
    >
      <Modal.Body>
        <p className="modal_heading">Create a new company</p>
        <Form className="mt-4">
          <Row>
          <Col md='10'>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="modal_label_text">
                  Company name
                </Form.Label>
                <Form.Control
                  className="modal_input"
                  type="text"
                  placeholder="Enter Company Name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className="modal_label_text">Password</Form.Label>
                <Form.Control
                  className="modal_input"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div className="mt-5">
          <button style={{backgroundColor: "var(--blue-color)"}} className="createModal_btn rounded text-white px-5 py-2">
            Enregistrer
          </button>
          <button onClick={()=> setCreateModal(false)} className="createModal_btn bg-white rounded border border-secondary-sadtle text-dark px-5 py-2 mx-2">
            Annuler
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateModal;
